import React from 'react';
import { Link } from 'react-router-dom';

const Portfolio = () => {
    return (
        <div className="my-4 py-4" id='portfolio'>
            <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Our Solutions</h2>
            <div className='flex justify-center'>
                <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
            </div>

            <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">  
                    {/* Mobile Development */}                          
                    <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                        <div className="m-2 text-justify text-sm">
                            <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Mobile App Development</h4>
                            <p className="text-md font-medium leading-5 h-auto md:h-48">
                                Our Mobile App Development service ensures high-performance, scalable, and secure applications tailored to your business needs. 
                                We specialize in both native and cross-platform development using cutting-edge technologies like React Native and Flutter. 
                                From UI/UX design to backend integration, our solutions provide seamless user experiences.
                            </p>
                            <div className="flex justify-center my-4">
                                <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                    Schedule Demo
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* Web Development */}
                    <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                        <div className="m-2 text-justify text-sm">
                            <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Web Development</h4>
                            <p className="text-md font-medium leading-5 h-auto md:h-48">
                                Our Web Development services focus on building scalable, responsive, and high-performance web applications.
                                We leverage modern frameworks like React.js, Next.js, and Node.js to deliver robust front-end and back-end solutions. 
                                Our web applications ensure optimal performance, SEO optimization, and seamless user experience. 
                            </p>
                            <div className="flex justify-center my-4">
                                <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                    Schedule Demo
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* VAPT */}
                    <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                        <div className="m-2 text-justify text-sm">
                            <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">Vulnerability Assessment & Penetration Testing (VAPT)</h4>
                            <p className="text-md font-medium leading-5 h-auto md:h-48">
                                Our VAPT services provide comprehensive security assessments to identify vulnerabilities in your applications, networks, and infrastructure. 
                                We conduct in-depth penetration testing to uncover potential security flaws before attackers can exploit them. 
                                Our security experts perform manual and automated testing, providing detailed reports with remediation strategies. 
                            </p>
                            <div className="flex justify-center my-4">
                                <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                    Schedule Demo
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* DevOps */}
                    <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                        <div className="m-2 text-justify text-sm">
                            <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">DevOps & Cloud Solutions</h4>
                            <p className="text-md font-medium leading-5 h-auto md:h-48">
                                Our DevOps services streamline software development and deployment by automating workflows and improving collaboration. 
                                We implement CI/CD pipelines, infrastructure as code (IaC), and monitoring solutions to ensure faster and more reliable releases. 
                                Our expertise includes AWS, Azure, Kubernetes, Docker, and Terraform for scalable cloud solutions. 
                            </p>
                            <div className="flex justify-center my-4">
                                <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                    Schedule Demo
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Portfolio;
