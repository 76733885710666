"use client"

import { useState } from "react"
import NavBar from "../components/Navbar/NavBar"
import Footer from "../components/Footer"
import { useDocTitle } from "../components/CustomHook"
import axios from "axios"
import Notiflix from "notiflix"

const DemoProduct = () => {

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    demoProducts: [],
  })
  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "checkbox"
          ? checked
            ? [...prevData.demoProducts, value]
            : prevData.demoProducts.filter((product) => product !== value)
          : value,
    }))
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }))
  }

  const validateForm = () => {
    const newErrors = {}
    if (!formData.firstName.trim()) newErrors.firstName = "First name is required"
    if (!formData.lastName.trim()) newErrors.lastName = "Last name is required"
    if (!formData.email.trim()) newErrors.email = "Email is required"
    if (!formData.phone.trim()) newErrors.phone = "Phone number is required"
    if (!formData.message.trim()) newErrors.message = "Message is required"
    if (formData.demoProducts.length === 0) newErrors.demoProducts = "Please select at least one product"
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const sendEmail = async (e) => {
    e.preventDefault()
    if (!validateForm()) return

    const submitBtn = document.getElementById("submitBtn")
    submitBtn.disabled = true
    submitBtn.innerHTML = "Loading..."

    try {
      console.log(process.env.REACT_APP_DEMO_REQUEST_API);
      const response = await axios.post(`${process.env.REACT_APP_DEMO_REQUEST_API}/demo`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })

      submitBtn.disabled = false
      submitBtn.innerHTML = "Send Message"
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
        demoProducts: [],
      })

      Notiflix.Report.success("Success", response.data.message, "Okay")
    } catch (error) {
      submitBtn.disabled = false
      submitBtn.innerHTML = "Send Message"

      if (error.response) {
        if (error.response.status === 500) {
          Notiflix.Report.failure("An error occurred", error.response.data.message, "Okay")
        }
        if (error.response.data.errors) {
          setErrors(error.response.data.errors)
        }
      } else {
        Notiflix.Report.failure("An error occurred", "Please try again later", "Okay")
      }
    }
  }

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div id="demo" className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
        <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
          <form onSubmit={sendEmail} id="demoProductForm">
            <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
              <div className="flex">
                <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">
                  Demo our products
                </h1>
              </div>
              {[
                "Website Development",
                "Mobile App Development",
                "VAPT Services",
                "Devops Services",
              ].map((product) => (
                <div key={product} className="flex items-center my-4">
                  <input
                    id={`checkbox-${product}`}
                    name="demoProducts"
                    type="checkbox"
                    className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
                    value={product}
                    checked={formData.demoProducts.includes(product)}
                    onChange={handleChange}
                  />
                  <label htmlFor={`checkbox-${product}`} className="ml-3 text-lg font-medium text-gray-900">
                    {product
                      .split("_")
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(" ")}
                  </label>
                </div>
              ))}
              {errors.demoProducts && <p className="text-red-500 text-sm">{errors.demoProducts}</p>}

              <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                {[
                  { name: "firstName", placeholder: "First Name*", type: "text" },
                  { name: "lastName", placeholder: "Last Name*", type: "text" },
                  { name: "email", placeholder: "Email*", type: "email" },
                  { name: "phone", placeholder: "Phone*", type: "tel" },
                ].map((field) => (
                  <div key={field.name}>
                    <input
                      name={field.name}
                      className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type={field.type}
                      placeholder={field.placeholder}
                      value={formData[field.name]}
                      onChange={handleChange}
                      required
                    />
                    {errors[field.name] && <p className="text-red-500 text-sm">{errors[field.name]}</p>}
                  </div>
                ))}
              </div>
              <div className="my-4">
                <textarea
                  name="message"
                  placeholder="Message*"
                  className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
                {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
              </div>
              <div className="my-2 w-1/2 lg:w-2/4">
                <button
                  type="submit"
                  id="submitBtn"
                  className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
                >
                  Send Message
                </button>
              </div>
            </div>
          </form>
          {/* Contact information sidebar */}
          <div className="w-full lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-blue-900 rounded-2xl">
            {/* ... (contact information and social media links remain unchanged) ... */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default DemoProduct

