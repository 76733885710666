import React from 'react';
import img from '../images/web.svg'; 
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';
import img5 from '../images/clients/MNT_Ic_VAPT.png';
import img6 from '../images/hosting.svg';

const Services = () => {
    return (
        <div id="services" className="bg-gray-100 py-12">
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Our Services</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
                        We offer cutting-edge solutions to ensure your business stays ahead in the digital era.
                    </h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                        {[ 
                             { 
                                img: img, 
                                title: "Web Development", 
                                desc: "We build scalable, high-performance websites tailored to your business needs. Our solutions ensure seamless user experience, responsive design, and optimized performance for growth and engagement." 
                              },
                              { 
                                img: img2, 
                                title: "Mobile App Development", 
                                desc: "Our team crafts feature-rich mobile applications for iOS and Android. We focus on intuitive UI/UX, cross-platform compatibility, and robust performance to enhance user engagement." 
                              },
                              { 
                                img: img5, 
                                title: "VAPT (Security Testing)", 
                                desc: "We conduct thorough security testing to identify and fix vulnerabilities. Our VAPT services include penetration testing, risk assessment, and compliance-driven audits for data protection." 
                              },
                              { 
                                img: img6, 
                                title: "DevOps & Cloud Solutions", 
                                desc: "We offer CI/CD automation, cloud optimization, and infrastructure management. Our services ensure faster deployments, high availability, and efficient cloud operations." 
                              }
                        ].map((service, index) => (
                            <div key={index} className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                    <img alt="service img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={service.img} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">{service.title}</h2>
                                    <p className="text-md font-medium">{service.desc}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Services;