import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t py-10">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5 py-8 md:py-12 border-t border-gray-200 text-left text-gray-600">
                
                {/* Company Info - Fully Centered */}
                <div className="flex justify-center">
                    <div className="border-b-4 border-blue-900 p-8 bg-gray-200 rounded-lg w-full max-w-xs text-center">
                        <h3 className="font-bold text-4xl mb-4">Opsin</h3>
                        <p className="text-md font-medium">Innovating the Future</p>
                    </div>
                </div>
                
                {/* Links */}
                <div className="flex flex-col items-center">
                    <h6 className="text-[#013289] text-xl font-bold mb-4">Quick Links</h6>
                    <ul className="text-md space-y-2">
                        <li><Link to="/" className="text-[#013289] hover:text-gray-900">Services</Link></li>
                        <li><Link to="/contact" className="text-[#013289] hover:text-gray-900">Contact</Link></li>
                        <li><Link to="/" className="text-[#013289] hover:text-gray-900">About</Link></li>
                    </ul>
                </div>
                
                {/* Social Media */}
                <div className="flex flex-col items-center">
                    <h6 className="text-[#013289] text-xl font-bold mb-4">Connect with Us</h6>
                    <a
                        href="https://www.linkedin.com/company/theopsin"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-900 hover:text-gray-500 transition duration-150 ease-in-out"
                        aria-label="LinkedIn"
                    >
                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27 4H5C4.4 4 4 4.4 4 5v22c0 .6.4 1 1 1h22c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1zM10 25H7V12h3v13zM8.5 10C7.1 10 6 8.9 6 7.5S7.1 5 8.5 5 11 6.1 11 7.5 9.9 10 8.5 10zM25 25h-3v-6.5c0-1.7-1.3-3-3-3s-3 1.3-3 3V25h-3V12h3v1.8c.9-1.3 2.5-2.1 4-2.1 3 0 5 2.4 5 5.5V25z" />
                        </svg>
                    </a>
                </div>
            </div>
            
            {/* Copyright */}
            <div className="text-center text-gray-600 text-sm font-semibold py-4">
                &copy; {new Date().getFullYear()} Opsin. All rights reserved.
            </div>
        </footer>
    );
};

export default Footer;
